<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <el-row type="flex" justify="center" class="mb-4xl">
        <img src="@/assets/images/logo.png" alt="Smarthand Logo" />
      </el-row>

      <el-form-item prop="user_name" class="mb-4xl">
        <el-input
          ref="user_name"
          v-model="loginForm.user_name"
          :disabled="callingAPI"
          placeholder="Tên đăng nhập"
          name="user_name"
          type="text"
          tabindex="1"
          autocomplete="on"
          prefix-icon="el-icon-user-solid"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="Caps lock is On"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :disabled="callingAPI"
            :type="passwordType"
            placeholder="Mật khẩu"
            name="password"
            show-password
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
            prefix-icon="el-icon-lock"
          />
        </el-form-item>
      </el-tooltip>

      <el-button
        :loading="callingAPI"
        type="primary"
        class="w-full mb-2xl"
        @click="handleLogin"
      >
        Đăng nhập
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { login, updateLoginInfo } from "@/services/auth";
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        user_name: "",
        password: "",
      },
      loginRules: {
        user_name: [
          {
            required: true,
            message: "Tên đăng nhập không được để trống",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Mật khẩu không được để trống",
            trigger: ["blur", "change"],
          },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      callingAPI: false,
    };
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },

    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    async handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true;
          try {
            const data = {
              user_name: this.loginForm.user_name,
              password: this.loginForm.password,
            };
            const response = await login(data);
            const loginInfo = {
              access_token: response.access_token,
              data: response.data,
              status: response.status,
              token_type: response.token_type,
            };
            updateLoginInfo(loginInfo);
            this.$router.push({ name: "Categories" });
            this.$notify.success({
              title: "Thành công",
              message: "Đăng nhập thành công",
              duration: 2000,
            });
            this.callingAPI = false;
          } catch (error) {
            this.callingAPI = false;
            this.$notify.error({
              title: "Thất bại",
              message: "Tên tài khoản hoặc mật khẩu không chính xác",
              duration: 2000,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .title-container {
    position: relative;
    .title {
      font-size: 22px;
      color: $light_gray;
      text-align: center;
      font-weight: bold;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
